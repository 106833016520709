import React from 'react';
import Immutable from 'immutable';

import DiscountEditor from './DiscountEditor';

export default class DiscountEditorContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      discount: Immutable.fromJS(this.props.discount)
    }
  }

  onUpdateDiscount(e) {
    e.preventDefault();

    var discountJSON = JSON.stringify(this.state.discount.toJS());
    discountJSON = discountJSON.replace(/"/g, '\\"');

    var house_id = window.houseId;
    var company_id = window.companyId;
    var product_version_id = this.state.discount.get('product_version_id');

    var url = '/companies/' + company_id + '/houses/' + house_id + '/product_versions/' + product_version_id + '/discounts/' + this.state.discount.get('id');

    let discount = this.state.discount;
    discount = discount.delete('product_version_id');
    discount = discount.set('discount_categories_attributes', discount.get('discount_categories'))
      .delete('discount_categories');

    const attributes = discount.get('discount_categories_attributes').map((discount_category) => {
      return Immutable.Map({
        category_name: discount_category.get('category_name'),
        excluded_products: discount_category.get('excluded_products'),
        sizes: discount_category.get('sizes')
      });
    });

    discount = discount.set('discount_categories_attributes', attributes);

    $.ajax({
      url: url,
      data: {
        discount: discount.toJS()
      },
      method: 'PUT'
    }).done((data) => {
      alert('Speichern erfolgreich');
    });
  }

  onDiscountChanged(discount) {
    this.setState({
      discount: discount
    });
  }

  render() {
    return <DiscountEditor productVersion={Immutable.fromJS(this.props.product_version)}
                           discount={this.state.discount}
                           onUpdateDiscount={this.onUpdateDiscount.bind(this)}
                           onDiscountChanged={this.onDiscountChanged.bind(this)} />;
  }

}

window.DiscountEditorContainer = DiscountEditorContainer;
