import $ from 'jquery';

function filter_form_reset_and_submit() {
  $("#filter_interval_type option[value='1']").attr('selected', '1');
  filter_form_submit();
}

window.filter_form_reset_and_submit = filter_form_reset_and_submit;

function filter_form_submit() {
  $('#filter_form').submit();
}
window.filter_form_submit = filter_form_submit;

$(document).ready(() => {
  ['#filter_from_day', '#filter_to_day', '#filter_increment_id'].forEach(function(selector) {
    $(selector).change(filter_form_reset_and_submit);
  });

  ['#filter_interval_type', '#filter_status', '#filter_shipping_method', '#filter_payment_method', '#filter_tag', '#search_customer'].forEach(function(selector) {
    $(selector).change(filter_form_submit);
  });
});
