import React from 'react';

import FullCalendar from 'fullcalendar-reactwrapper';

export default class ZbonCalendar extends React.Component {

  render() {
    console.log(this.props);
    return (<div>
      <FullCalendar {...this.props} />
    </div>);
  }
}

window.ZbonCalendar = ZbonCalendar;
