import React from 'react';

import DiscountCategories from './DiscountCategories';
import DiscountShipping from './DiscountShipping';
import DiscountProducts from './DiscountProducts';
import DiscountSettings from './DiscountSettings';
import DiscountConditions from './DiscountConditions';

export default class DiscountEditor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ui: 'shipping'
    };
  }

  onCategories() {
    this.setState({
      ui: 'categories'
    });
  }

  onShipping(){
    this.setState({
      ui: 'shipping'
    });
  }

  onProducts() {
    this.setState({
      ui: 'products'
    });
  }

  onDiscount() {
    this.setState({
      ui: 'discount'
    });
  }

  onConditions() {
    this.setState({
      ui: 'conditions'
    });
  }

  render() {
    var body = null;
    var discount = this.props.discount;

    if(!this.props.discount) {
      return (
        <span>Rabatt wird geladen ...</span>
      );
    }

    if(this.state.ui === 'shipping') {
      body = <DiscountShipping discount={discount}
                               onDiscountChanged={this.props.onDiscountChanged} />
    }
    else if(this.state.ui === 'categories') {
      body = <DiscountCategories discount={discount}
                                 productVersion={this.props.productVersion}
                                 onDiscountChanged={this.props.onDiscountChanged} />
    }
    else if(this.state.ui === 'products') {
      body = <DiscountProducts discount={discount}
                               productVersion={this.props.productVersion}
                               onDiscountChanged={this.props.onDiscountChanged} />;
    }
    else if(this.state.ui === 'discount') {
      body = <DiscountSettings discount={discount}
                               onDiscountChanged={this.props.onDiscountChanged} />;
    }
    else { // conditions
      body = <DiscountConditions discount={discount}
                                 onDiscountChanged={this.props.onDiscountChanged}/>;
    }

    return (<div>
      <ul className="nav nav-tabs" id="editor-nav">
        <li id="shipping"
            className="nav-item">
          <a href="#" className={ this.state.ui == 'categories nav-link' ? 'active': 'nav-link' }  onClick={this.onShipping.bind(this)}>Zustellart</a>
        </li>
        <li id="categories"
          className="nav-item">
          <a href="#" className={ this.state.ui == 'categories' ? 'active nav-link': 'nav-link' } onClick={this.onCategories.bind(this)}>Warengruppe</a>
        </li>
        <li id="products"
          className="nav-item">
          <a href="#" className={ this.state.ui == 'products' ? 'active nav-link': 'nav-link' } onClick={this.onProducts.bind(this)}>Artikel</a>
          </li>
        <li id="discount"
          className="nav-item">
          <a href="#" className={ this.state.ui == 'discount' ? 'active nav-link': 'nav-link' } onClick={this.onDiscount.bind(this)}>Rabatt</a>
        </li>
        <li id="conditions"
          className="nav-item">
          <a href="#" className={ this.state.ui == 'conditions' ? 'active nav-link': 'nav-link' } onClick={this.onConditions.bind(this)}>Bedingungen</a>
        </li>
      </ul>
      <hr />
      <div className='container'>
        {body}
        <hr />
        <button className='btn btn-danger' onClick={this.props.onUpdateDiscount}>
          Speichern
        </button>
      </div>
    </div>);
  }

}
